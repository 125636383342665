import md5 from 'md5'
import { logException, setSentryMWSData } from 'src/js/config/sentry'
import { getSelectedAmazonSellerAccount } from 'helpers/amazon_seller_account'
import Api from 'services/api'
import {
  getPathData,
  sendSegmentTrackEvent,
  safeSegmentCall
} from 'services/segment'
import { getProductForSegment, sendSegmentMWSStepEvent } from 'helpers/segment'
import { extractMswSyncLocationForPendo } from 'helpers/account/users'
import { getDefaultMarketplaceForSellerId } from 'helpers/account'
import { getPathnameFromFullHashUrl } from 'helpers/url'
import { SET_AMAZON_SELLER_ACCOUNTS_DATA } from 'constants/action_types/amazon_seller_accounts'
import {
  AMAZON_MWS_PPC_SYNC_STEPS,
  AMAZON_SELLER_ACCOUNT_MODALS,
  PPC_SYNC_STATUS
} from 'constants/account'
import { MARKETPLACES, MARKETPLACES_REGIONS } from 'constants/countries'
import {
  AMAZON_CONNECTION_SURVEY,
  triggerUsabillaCampaign
} from 'services/usabilla_live'
import * as ACCOUNT_ACTIONS from '../../constants/action_types/accounts'
import { createNotification } from '../notifications'

export const showMwsSyncModal = (
  modal,
  isUpgrade = false,
  noHeader = false
) => dispatch => {
  dispatch({
    type: ACCOUNT_ACTIONS.ACCOUNT_MWS_SYNC_SHOW_MODAL,
    payload: {
      modal,
      isUpgrade,
      noHeader
    }
  })
}
export const closeMwsSyncModal = () => dispatch => {
  dispatch({
    type: ACCOUNT_ACTIONS.ACCOUNT_MWS_SYNC_CLOSE_MODAL
  })
}

export const mwsSyncSetRegion = region => dispatch => {
  dispatch({
    type: ACCOUNT_ACTIONS.ACCOUNT_MWS_SYNC_SET_REGION,
    payload: region
  })
}

export const mwsSyncSetMarketplace = region => async (dispatch, getState) => {
  dispatch({
    type: ACCOUNT_ACTIONS.ACCOUNT_MWS_SYNC_SET_MARKETPLACE,
    payload: region
  })

  const { availableMarketplaces, data } = getState().account.mwsSync
  const { appType } = getState().globalData
  const regionCode = data.region

  sendSegmentMWSStepEvent(
    'Marketplace chosen',
    regionCode,
    availableMarketplaces,
    region,
    appType
  )
}

export const mwsSyncSetSellerId = sellerId => dispatch => {
  dispatch({
    type: ACCOUNT_ACTIONS.ACCOUNT_MWS_SYNC_SET_SELLER_ID,
    payload: sellerId
  })
}

export const mwsSyncSetAuthToken = token => dispatch => {
  dispatch({
    type: ACCOUNT_ACTIONS.ACCOUNT_MWS_SYNC_SET_AUTH_TOKEN,
    payload: token
  })
}

export const mwsSyncSetAccountName = name => dispatch => {
  dispatch({
    type: ACCOUNT_ACTIONS.ACCOUNT_MWS_SYNC_SET_ACCOUNT_NAME,
    payload: name
  })
}

export const mwsSyncSetSellerEmail = emailAddress => dispatch => {
  dispatch({
    type: ACCOUNT_ACTIONS.ACCOUNT_MWS_SYNC_SET_SELLER_EMAIL,
    payload: emailAddress
  })
}

export const mwsSyncGetStatus = () => async (dispatch, getState) => {
  dispatch({
    type:
      ACCOUNT_ACTIONS.ACCOUNT_AMAZON_SELLER_ACCOUNT_GET_STATUS_PENDING_STATUS
  })

  const sellerId = getState().globalData.user.id

  const res = await Api.getAmazonSellerAccountStatus(sellerId)

  if (res.ok) {
    dispatch({
      type: ACCOUNT_ACTIONS.ACCOUNT_AMAZON_SELLER_ACCOUNT_GET_STATUS_COMPLETE,
      payload: res.data
    })
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Failed to fetch amazon seller account status'
      })
    )
  }

  dispatch({
    type:
      ACCOUNT_ACTIONS.ACCOUNT_AMAZON_SELLER_ACCOUNT_GET_STATUS_COMPLETE_STATUS,
    payload: {
      success: res.ok
    }
  })

  return res.ok
}

export const getAmazonSellerAccounts = () => async dispatch => {
  const res = await Api.getAmazonSellerAccounts()

  if (res.ok) {
    dispatch({
      type: SET_AMAZON_SELLER_ACCOUNTS_DATA,
      payload: {
        amazonSellerAccounts: res.data,
        sellerDataAvailable: true
      }
    })
  }
}

export const mwsPpcSyncCreateNewAccount = (
  account,
  isUpgrade = false
) => async (dispatch, getState) => {
  const {
    sessions: { locationHistory },
    globalData: { appType }
  } = getState()

  dispatch({
    type: ACCOUNT_ACTIONS.ACCOUNT_AMAZON_SELLER_ACCOUNT_CREATE_PENDING_STATUS
  })
  const location = extractMswSyncLocationForPendo(locationHistory)
  const res = await Api.createSellerAccount(account, location)
  const upgradeRes = { ok: true }

  if (res.ok) {
    dispatch({
      type: ACCOUNT_ACTIONS.ACCOUNT_AMAZON_SELLER_ACCOUNT_CREATE_COMPLETE,
      payload: res.data
    })

    const token = account.auth_token || ''

    safeSegmentCall(() => {
      const newAccount = res.data
      const defaultCountry = newAccount.countries?.find(ctry => ctry.is_default)
      sendSegmentTrackEvent('MWS Connected', {
        mwsKey: md5(token),
        sellerId: newAccount?.merchant_id,
        product: getProductForSegment(appType),
        region: MARKETPLACES_REGIONS[newAccount?.region?.toLowerCase()],
        marketplace:
          MARKETPLACES?.[defaultCountry?.country_code?.toLowerCase()]?.name,
        tab: getPathData(getPathnameFromFullHashUrl(document.URL))?.name
      })
    })

    if (token === '') {
      logException('User mws auth_token not present', {
        level: 'warning',
        extra: {
          account
        }
      })
    }

    triggerUsabillaCampaign(AMAZON_CONNECTION_SURVEY)

    // load the MWS sync status
    await dispatch(mwsSyncGetStatus())
  } else {
    dispatch({
      type: ACCOUNT_ACTIONS.ACCOUNT_AMAZON_SELLER_ACCOUNT_CREATE_ERROR,
      payload: res.error
    })
  }

  dispatch({
    type: ACCOUNT_ACTIONS.ACCOUNT_AMAZON_SELLER_ACCOUNT_CREATE_COMPLETE_STATUS,
    payload: {
      success: res.ok && upgradeRes.ok,
      isUpgrade
    }
  })

  if (res.ok) {
    await dispatch(getAmazonSellerAccounts())
    const state = getState()
    const {
      amazon_seller_account_id,
      marketplace_id
    } = getSelectedAmazonSellerAccount(state)
    setSentryMWSData({ amazon_seller_account_id, marketplace_id })
  }

  return res.ok
}

export const mwsSyncCloseStatusBar = () => (dispatch, getState) => {
  dispatch({
    type: ACCOUNT_ACTIONS.ACCOUNT_AMAZON_SELLER_ACCOUNT_CLOSE_STATUS_BAR
  })

  const state = getState()
  const sellerId = state.globalData.user.id
  const amazonSellerAccount =
    state.amazonSellerAccounts.amazonSellerAccounts.find(
      account => account.display_sync_status
    ) || {}

  // we don't really care if this fails, worst case scenario the bar appears next time they re-load the page
  // there's no positive action we need to take, so just fire & forget
  Api.patchDismissStatusBar(sellerId, amazonSellerAccount.id)
}

export const mwsSyncToggleDetailsModal = () => dispatch => {
  dispatch({
    type:
      ACCOUNT_ACTIONS.ACCOUNT_AMAZON_SELLER_ACCOUNT_TOGGLE_SYNC_DETAILS_MODAL
  })
}

export const getAmazonMarketplacesList = () => async dispatch => {
  dispatch({
    type: ACCOUNT_ACTIONS.ACCOUNT_MARKETPLACE_LIST_LOAD_PENDING_STATUS
  })

  const res = await Api.getAmazonMarketplacesList()

  if (res.ok) {
    dispatch({
      type: ACCOUNT_ACTIONS.ACCOUNT_MARKETPLACE_LIST_LOAD_COMPLETE,
      payload: res.data
    })
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Failed to load amazon seller account marketplaces'
      })
    )
  }

  dispatch({
    type: ACCOUNT_ACTIONS.ACCOUNT_MARKETPLACE_LIST_LOAD_COMPLETE_STATUS
  })
}

export const mwsSyncSetPpcSyncStatus = status => dispatch => {
  dispatch({
    type: ACCOUNT_ACTIONS.ACCOUNT_MWS_SYNC_SET_PPC_SYNC_STATUS,
    payload: status
  })
}

export const mwsSyncSetPpcSyncStepSkipped = stepSkipped => dispatch => {
  dispatch({
    type: ACCOUNT_ACTIONS.ACCOUNT_MWS_SYNC_SET_PPC_SYNC_STEP_SKIPPED,
    payload: stepSkipped
  })
}

export const setHasPpcAccess = accountId => dispatch => {
  if (accountId) {
    dispatch({
      type: ACCOUNT_ACTIONS.ACCOUNT_AMAZON_SELLER_SET_HAS_PPC_ACCESS,
      payload: accountId
    })
  }
}

export const mwsPpcSyncAuthenticationFailed = params => dispatch => {
  const { sellerAccountId, withMwsSync, errMessage } = params

  const [modalShowAction, modal] = withMwsSync
    ? [
        ACCOUNT_ACTIONS.ACCOUNT_MWS_SYNC_SHOW_MODAL,
        AMAZON_MWS_PPC_SYNC_STEPS.ppcSync
      ]
    : [
        ACCOUNT_ACTIONS.ACCOUNT_AMAZON_SELLER_ACCOUNT_SHOW_MODAL,
        AMAZON_SELLER_ACCOUNT_MODALS.enablePPC
      ]

  dispatch({
    type: modalShowAction,
    payload: {
      modal,
      sellerAccountId,
      ppcSyncStatus: PPC_SYNC_STATUS.failure
    }
  })

  dispatch(
    createNotification({
      message:
        errMessage ||
        'Something went wrong authenticating your PPC credentials',
      level: 'error',
      title: 'Failed to validate your PPC account credentials'
    })
  )
}

export const mwsPpcSyncCreateCredentials = params => async (
  dispatch,
  getState
) => {
  const { sellerAccountId, authCode, withMwsSync, originPath } = params
  const {
    globalData: { appType },
    amazonSellerAccounts: { amazonSellerAccounts }
  } = getState()

  const res = await Api.createPpcCredentials({
    sellerAccountId,
    authCode,
    location: originPath
  })

  if (res.ok) {
    const [modalShowAction, modal] = withMwsSync
      ? [
          ACCOUNT_ACTIONS.ACCOUNT_MWS_SYNC_SHOW_MODAL,
          AMAZON_MWS_PPC_SYNC_STEPS.accountSetup
        ]
      : [
          ACCOUNT_ACTIONS.ACCOUNT_AMAZON_SELLER_ACCOUNT_SHOW_MODAL,
          AMAZON_SELLER_ACCOUNT_MODALS.ppcSyncSuccess
        ]

    const modalPayload = {
      modal,
      sellerAccountId
    }

    dispatch({
      type: modalShowAction,
      payload: modalPayload
    })
    // This will set has_ppc_access to enabled for the short flow
    if (!withMwsSync) {
      dispatch(setHasPpcAccess(sellerAccountId))
    }
    await dispatch(getAmazonSellerAccounts())

    safeSegmentCall(() => {
      sendSegmentTrackEvent('PPC Connected', {
        entityId: sellerAccountId,
        product: getProductForSegment(appType),
        tab: getPathData(getPathnameFromFullHashUrl(document.URL))?.name,
        marketplace: getDefaultMarketplaceForSellerId(
          amazonSellerAccounts,
          sellerAccountId
        )?.name
      })
    })
  } else {
    dispatch(
      mwsPpcSyncAuthenticationFailed({
        sellerAccountId,
        withMwsSync,
        errMessage: res.error
      })
    )
  }
}

export const mwsSyncAuthenticationSucceeded = ({
  label,
  region,
  email,
  primaryMarketplaceId,
  mwsAuthToken,
  spapiOauthCode,
  sellingPartnerId
}) => dispatch => {
  dispatch({
    type: ACCOUNT_ACTIONS.ACCOUNT_MWS_SYNC_SET_DATA,
    payload: {
      label,
      region,
      sc_email: email,
      primary_marketplace_id: primaryMarketplaceId,
      auth_token: mwsAuthToken,
      spapi_oauth_code: spapiOauthCode,
      merchant_id: sellingPartnerId
    }
  })

  dispatch({
    type: ACCOUNT_ACTIONS.ACCOUNT_MWS_SYNC_SHOW_MODAL,
    payload: {
      modal: AMAZON_MWS_PPC_SYNC_STEPS.accountSync,
      mwsSyncStatus: PPC_SYNC_STATUS.success
    }
  })
}

export const mwsSyncAuthenticationFailed = ({
  label,
  region,
  email,
  primaryMarketplaceId
}) => dispatch => {
  dispatch({
    type: ACCOUNT_ACTIONS.ACCOUNT_MWS_SYNC_SET_DATA,
    payload: {
      label,
      region,
      sc_email: email,
      primary_marketplace_id: primaryMarketplaceId
    }
  })

  dispatch({
    type: ACCOUNT_ACTIONS.ACCOUNT_MWS_SYNC_SHOW_MODAL,
    payload: {
      modal: AMAZON_MWS_PPC_SYNC_STEPS.accountSync,
      mwsSyncStatus: PPC_SYNC_STATUS.failure
    }
  })
}
