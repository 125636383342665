import * as accountActionType from 'constants/action_types/accounts'
import { persistGlobalData } from 'helpers/storage'

export const enableCNTheme = cnThemeEnabled => dispatch => {
  dispatch({
    type: accountActionType.ENABLE_CNTHEME,
    payload: { cnThemeEnabled }
  })
}
// todo: save theme choice to backend
export const saveThemeChoice = () => (dispatch, getState) => {
  persistGlobalData(getState())
}
// when user click another theme it will change immediately
export const updateTheme = theme => dispatch => {
  dispatch({
    type: accountActionType.UPDATE_THEME,
    payload: { theme }
  })
}
